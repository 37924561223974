import React from 'react';
import Image from 'next/image';
import Fade from '../animation/Fade';

const Banner = () => {
  return (
    <Fade
      as='div'
      duration={0.25}
      className='aspect-1 relative overflow-hidden rounded-lg sm:aspect-[3/2] lg:aspect-[3/1]'
    >
      <Image
        src='/images/banner/ittihad-banner-large.png'
        alt='ittihad-banner'
        className='inset-0 hidden object-left lg:block'
        fill
        sizes='2100px'
        priority
      />
      <Image
        src='/images/banner/ittihad-banner-mid.png'
        alt='ittihad-banner'
        className='inset-0 hidden object-contain object-center sm:block lg:hidden'
        fill
        sizes='1200px'
        priority
      />
      <Image
        src='/images/banner/ittihad-banner-small.png'
        alt='ittihad-banner'
        className='inset-0 block object-left sm:hidden'
        fill
        sizes='500px'
        priority
      />
    </Fade>
  );
};

export default Banner;
